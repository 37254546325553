import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`I recently had to generate a custom image from a Node.js backend, and I chose to use `}<a parentName="p" {...{
        "href": "https://sharp.pixelplumbing.com/"
      }}>{`Sharp`}</a>{` to take an SVG and convert it into a PNG, since we were already using Sharp and it seemed like the most feasible thing to do.`}</p>
    <p>{`However, I found it a bit less unintuitive how to add custom fonts to the rendered image. I found that locally - given I had the required font installed on my OS - it would work just fine. However, in production font's wouldn't load correctly.`}</p>
    <p>{`So I thought I'd write this step-by-step guide because that's what I was missing when researching this.`}</p>
    <h3>{`libvips and Sharp`}</h3>
    <p>{`So `}<inlineCode parentName="p">{`Sharp`}</inlineCode>{` is responsible for rendering the PNG based on the SVG, and Sharp uses `}<inlineCode parentName="p">{`libvips`}</inlineCode>{` which uses `}<inlineCode parentName="p">{`librsvg`}</inlineCode>{` under the hood which uses `}<a parentName="p" {...{
        "href": "https://www.freedesktop.org/software/fontconfig/fontconfig-user.html"
      }}>{`fontconfig`}</a>{` (yeah, good luck on that documentation).`}</p>
    <p>{`So the thing to do is:`}</p>
    <ol>
      <li parentName="ol">{`Ensure `}<inlineCode parentName="li">{`fontconfig`}</inlineCode>{` is installed`}</li>
      <li parentName="ol">{`Ensure your fonts are valid, in the correct format and present in the environment`}</li>
    </ol>
    <h3>{`Uploading your fonts`}</h3>
    <p>{`What I did was to create a `}<inlineCode parentName="p">{`fonts`}</inlineCode>{` folder inside my project root (feel free to place this wherever you see fit), and then ensure that this folder is copied to the correct location when building the image.`}</p>
    <p>{`Another thing to note is that initially I tried with `}<inlineCode parentName="p">{`.otf`}</inlineCode>{` fonts, however, that didn't seem to work, so I instead converted them to `}<inlineCode parentName="p">{`.ttf`}</inlineCode>{` using `}<a parentName="p" {...{
        "href": "https://cloudconvert.com/otf-to-ttf"
      }}>{`cloudconvert`}</a>{`.`}</p>
    <p>{`Next step is to ensure `}<inlineCode parentName="p">{`fontconfig`}</inlineCode>{` is installed in your environment.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`fontconfig`}</inlineCode>{` in your `}<inlineCode parentName="h3">{`Dockerfile`}</inlineCode></h3>
    <p>{`I use Docker to build my applications, so I changed my `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` to look something like this:`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "dockerfile",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`FROM`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` node:16-bullseye-slim `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`AS`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` production`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`COPY`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` --chown=node:node --from=build /usr/src/app/node_modules ./node_modules`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`COPY`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` --chown=node:node --from=build --chmod=755 /usr/src/app/fonts ./usr/local/share/fonts`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`RUN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` apt-get update; apt-get install -y fontconfig`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`RUN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` fc-cache -f -v`}</span></span></span></code></pre>
    <p>{`This will:`}</p>
    <ol>
      <li parentName="ol">{`Copy the `}<inlineCode parentName="li">{`fonts`}</inlineCode>{` folder in your project root to `}<inlineCode parentName="li">{`/usr/local/share/fonts`}</inlineCode>{` (which is one of the locations `}<inlineCode parentName="li">{`fontconfig`}</inlineCode>{` will look for fonts)`}</li>
      <li parentName="ol">{`Install `}<inlineCode parentName="li">{`fontconfig`}</inlineCode>{` and clear any font cache - which might not be needed, but is a cheap thing to do.`}</li>
    </ol>
    <p>{`You can also exec/ssh into your running instance and install fontconfig using: `}<inlineCode parentName="p">{`apt-get update; apt-get install -y fontconfig`}</inlineCode>{`.
Afterwards you may run `}<inlineCode parentName="p">{`fc-cache -f -v`}</inlineCode>{` to find out where exactly `}<inlineCode parentName="p">{`fontconfig`}</inlineCode>{` tries to look for fonts (thats how I found `}<inlineCode parentName="p">{`/usr/local/share/fonts`}</inlineCode>{`).`}</p>
    <p>{`That's it. Afterwards my custom fonts were rendering correctly. My SVG would look something like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "xml",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`text`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`font-family`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Cera Pro`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`" `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`font-size`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`44`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`" `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`font-weight`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`500`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`">`}</span></span></span></code></pre>
    <h3>{`Debugging`}</h3>
    <p>{`If you're still having issues try these steps:`}</p>
    <ol>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`fc-list`}</inlineCode>{` this will list which fonts `}<inlineCode parentName="li">{`fontconfig`}</inlineCode>{` have found`}</li>
      <li parentName="ol">{`You may need a `}<inlineCode parentName="li">{`fonts.conf`}</inlineCode>{` file inside your `}<inlineCode parentName="li">{`fonts`}</inlineCode>{` folder (I did seemingly not need that)`}</li>
    </ol>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "xml",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<?`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`xml`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{` version`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`1.0`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"?>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`DOCTYPE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fontconfig`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{` SYSTEM "fonts.dtd">`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`fontconfig`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`	`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`dir`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`/fonts`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`dir`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`	`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`config`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`></`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`config`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`fontconfig`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span></code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Try to `}<a parentName="li" {...{
          "href": "https://support.apple.com/en-gb/guide/font-book/fntbk1000/mac#aria-fntb7809e11b"
        }}>{`validate your font`}</a></li>
      <li parentName="ol">{`Feel free to comment in the comment section and I shall try to help out`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      